import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import AuthRoute from "./components/ProtectedRoutes/AuthRoute";
import GuestRoute from "./components/ProtectedRoutes/GuestRoute";
import FallbackError from "./components/Errors/FallbackError";

// Actions
import { fetchProduct } from "./pages/Product/actions/fetchProduct";

// Layouts
import MainLayout from "./layouts/MainLayout";
import HeroLayout from "./layouts/HeroLayout";
import SignupLayout from "./layouts/SignupLayout";
import CreatorDashboardLayout from "./layouts/CreatorDashboardLayout";
import SettingsLayout from "./layouts/SettingsLayout";
import RootLayout from "./layouts/RootLayout";
import OrderLayout from "./layouts/OrderLayout";

// Pages
const ResultPage = lazy(() => import("./pages/Search/Result"));
const SearchPage = lazy(() => import("./pages/Search"));
const PayoutsPage = lazy(() => import("./pages/Payouts"));
const LandingPage = lazy(() => import("./pages/Landing"));
const SubscriptionPage = lazy(() => import("./pages/Subscription"));
const CartPage = lazy(() => import("./pages/Cart"));
const PurchaseHistoryPage = lazy(() => import("./pages/Cart/History"));
const LoginPage = lazy(() => import("./pages/Auth/Login"));
const SignUpPage = lazy(() => import("./pages/Auth/SignUp"));
const NotFoundPage = lazy(() => import("./pages/404"));
const FAQPage = lazy(() => import("./pages/FAQ"));
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicy"));
const TermsPage = lazy(() => import("./pages/Terms"));
const ContentGuidelinesPage = lazy(() => import("./pages/ContentGuidelines"));
const AboutUsPage = lazy(() => import("./pages/AboutUs"));
const InviteFriendsPage = lazy(() => import("./pages/InviteFriends"));
const ResetPasswordPage = lazy(() => import("./pages/Auth/ResetPassword"));
const BecomeCreatorPage = lazy(() => import("./pages/BecomeCreator"));
const UploadPage = lazy(() => import("./pages/Upload"));
const PurchasesPage = lazy(() => import("./pages/CreatorDashboard/Purchases"));
const ProductPage = lazy(() => import("./pages/Product"));
const SalesPage = lazy(() => import("./pages/CreatorDashboard/Sales"));
const UsersPage = lazy(() => import("./pages/CreatorDashboard/Users"));
const BusinessSalesPage = lazy(
  () => import("./pages/CreatorDashboard/Sales/Business")
);
const CreatedPage = lazy(() => import("./pages/CreatorDashboard/Created"));
const PersonalInfoPage = lazy(() => import("./pages/Settings/PersonalInfo"));
const PaymentInfoPage = lazy(() => import("./pages/Settings/PaymentInfo"));
const CollectionsPage = lazy(
  () => import("./pages/CreatorDashboard/Collections")
);
const OrderSuccessPage = lazy(() => import("./pages/Order/Success"));
const OrderCanceledPage = lazy(() => import("./pages/Order/Canceled"));

const routes = [
  {
    path: "/",
    errorElement: <FallbackError />,
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <HeroLayout />,
        children: [
          {
            path: "/",
            element: <LandingPage />,
          },
          {
            path: "faq",
            element: <FAQPage />,
          },
          {
            path: "content-guidelines",
            element: <ContentGuidelinesPage />,
          },
        ],
      },
      {
        path: "/",
        element: <AuthRoute element={<OrderLayout />} />,
        children: [
          {
            path: "orderSuccess",
            element: <OrderSuccessPage />,
          },
          {
            path: "orderCancel",
            element: <OrderCanceledPage />,
          },
        ],
      },
      {
        path: "about-us",
        element: <AboutUsPage />,
      },
      {
        path: "/",
        element: <MainLayout />,
        children: [
          {
            path: "privacy-policy",
            element: <PrivacyPolicyPage />,
          },
          {
            path: "terms-of-use",
            element: <TermsPage />,
          },
        ],
      },
      {
        path: "search",
        element: <SearchPage />,
      },
      {
        path: "search/:type",
        element: <ResultPage />,
      },
      {
        path: "product/:productId",
        element: <ProductPage />,
        loader: fetchProduct,
      },
      {
        path: "/plans",
        element: <AuthRoute element={<SubscriptionPage />} />,
      },
      {
        path: "/payouts",
        element: <PayoutsPage />,
      },
      {
        path: "/cart",
        children: [
          {
            path: "",
            index: true,
            element: <AuthRoute element={<CartPage />} />,
          },
          {
            path: "history",
            element: <AuthRoute element={<PurchaseHistoryPage />} />,
          },
        ],
      },
      {
        path: "/settings",
        element: <AuthRoute element={<SettingsLayout />} />,
        children: [
          {
            index: true,
            element: <PersonalInfoPage />,
          },
          {
            path: "payment-info",
            element: <PaymentInfoPage />,
          },
        ],
      },
      {
        path: "/",
        element: <SignupLayout />,
        children: [
          {
            path: "sign-up",
            element: <GuestRoute element={<SignUpPage />} />,
          },
        ],
      },
      {
        path: "/",
        element: <SignupLayout creatorPage={true} />,
        children: [
          {
            path: "become-creator",
            element: <AuthRoute element={<BecomeCreatorPage />} />,
          },
        ],
      },
      {
        path: "/creator-dashboard",
        element: <AuthRoute element={<CreatorDashboardLayout />} />,
        children: [
          {
            path: "purchases",
            element: <AuthRoute element={<PurchasesPage />} />,
          },
          {
            path: "sales",
            element: <AuthRoute element={<SalesPage />} creatorPage={true} />,
          },
          {
            path: "business-sales",
            element: <BusinessSalesPage />,
          },
          {
            path: "",
            element: <AuthRoute element={<CreatedPage />} creatorPage={true} />,
          },
          {
            path: "collections",
            element: <CollectionsPage />,
          },
          {
            path: "users",
            element: <UsersPage />,
          },
        ],
      },
      {
        path: "sign-in",
        element: <GuestRoute element={<LoginPage />} />,
      },
      {
        path: "reset-password",
        element: <GuestRoute element={<ResetPasswordPage />} />,
      },
      {
        path: "invite-friends",
        element: <AuthRoute element={<InviteFriendsPage />} />,
      },
      {
        path: "upload",
        element: <AuthRoute element={<UploadPage />} creatorPage={true} />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
