import clsx from "clsx";
import { twMerge } from "tailwind-merge";

interface DividerProps {
  children?: React.ReactNode;
  gradient?: boolean;
  className?: string;
  vertical?: boolean;
}

const Divider = ({
  children,
  className = "",
  gradient = false,
  vertical = false,
}: DividerProps) => {
  return (
    <div
      className={twMerge(
        clsx(
          {
            "relative bg-tertiary-main dark:bg-tertiary-gray flex items-center justify-center":
              !gradient,
            "mx-auto from-[#CACACA] via-[#646464] to-[#CACACA] dark:from-[#646464] dark:via-[#CACACA] dark:to-[#646464] opacity-60":
              gradient,
            "bg-gradient-to-b": vertical && gradient,
            "bg-gradient-to-r": !vertical && gradient,
            "w-full h-[1px]": !vertical,
            "h-full w-[1px]": vertical,
          },
          className || ""
        )
      )}
    >
      {children && (
        <span className="bg-white dark:bg-black p-2 px-3">{children}</span>
      )}
    </div>
  );
};

export default Divider;
