import PageLoader from "@/components/PageLoader";
import useFetchUserInfo from "@/hooks/useFetchUserInfo";

interface UserInfoProviderProps {
  children: React.ReactElement;
}

const UserInfoProvider = ({ children }: UserInfoProviderProps) => {
  const { loading } = useFetchUserInfo();

  if (loading) {
    return <PageLoader />;
  }

  return children;
};

export default UserInfoProvider;
