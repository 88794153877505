import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CartItem } from "@/shared/types/cartItem";

interface CartState {
  id: string | null;
  items?: CartItem[];
  price: {
    originalPrice: number;
    finalPrice: number;
    couponCode?: string | null;
    discountPercentage: number;
  };
  options: {
    animate: boolean;
  };
}

const initialState: CartState = {
  id: null,
  items: [],
  options: {
    animate: false,
  },
  price: {
    originalPrice: 0,
    finalPrice: 0,
    discountPercentage: 0,
  },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    initializeCart: (
      state,
      action: PayloadAction<{
        id: string;
        items: CartItem[];
        animate?: boolean;
      }>
    ) => {
      const { id, items, animate } = action.payload;
      state.id = id;
      state.items = items;
      state.options.animate = animate ?? false;
    },
    setPrices: (
      state,
      action: PayloadAction<{
        originalPrice: number;
        finalPrice: number;
        discountPercentage: number;
        couponCode?: string;
      }>
    ) => {
      state.price = {
        originalPrice: action.payload.originalPrice,
        finalPrice: action.payload.finalPrice,
        discountPercentage: action.payload.discountPercentage,
        couponCode: action.payload.couponCode,
      };
    },
    addToCart: (
      state,
      action: PayloadAction<{ item: CartItem; animate: boolean }>
    ) => {
      const item = action.payload.item;
      state.options.animate = action.payload.animate ?? false;
      if (
        state.items?.find((cartItem) => cartItem.productId === item.productId)
      ) {
        return;
      }
      state.items = state.items ? [...state.items, item] : [item];
    },
    resetAnimation: (state) => {
      state.options.animate = false;
    },
    removeProduct: (state, action: PayloadAction<string>) => {
      const cartItemId = action.payload;
      state.items = state.items?.filter(
        (item) => item.cardItemId !== cartItemId
      );
    },
    resetCart: (state) => {
      return initialState;
    },
  },
});

export const {
  initializeCart,
  addToCart,
  resetAnimation,
  resetCart,
  removeProduct,
  setPrices,
} = cartSlice.actions;

export const cartPriceSelector = (state: RootState) => state.cartSlice.price;
export const cartIdSelector = (state: RootState) => state.cartSlice.id;
export const cartItemsSelector = (state: RootState) => state.cartSlice.items;
export const cartProductsCountSelector = (state: RootState) =>
  state.cartSlice.items?.length ?? 0;
export const cartAnimateSelector = (state: RootState) =>
  state.cartSlice.options.animate;

export default cartSlice.reducer;
