import clsx from "clsx";
import { twMerge } from "tailwind-merge";

interface DefaultAvatarProps {
  src?: string | null;
  alt?: string | null;
  className?: string;
  imgClassName?: string;
  status?: "online" | "offline" | "busy";
}

const DefaultAvatar = ({
  src,
  alt,
  className,
  imgClassName,
  status,
}: DefaultAvatarProps) => {
  const AvatarComponent = (
    <img
      src={src || "/images/pfp.png"}
      className={twMerge(
        "size-full rounded-full object-cover border-[4px] border-[#F5F5F6] dark:border-tertiary-dark-gray mx-auto",
        imgClassName ? imgClassName : className
      )}
      alt={alt || ""}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = "/images/pfp.png";
      }}
    />
  );

  if (status) {
    return (
      <div className={twMerge("relative", className)}>
        {AvatarComponent}
        <span
          className={clsx(
            "absolute size-[10px] border border-white dark:border-tertiary-dark-gray rounded-full end-0 bottom-0.5",
            {
              "bg-accent-blue dark:bg-accent-main": status === "online",
              "bg-tertiary-gray": status === "offline",
              "bg-[#FF0000]": status === "busy",
            }
          )}
        ></span>
      </div>
    );
  }

  return AvatarComponent;
};

export default DefaultAvatar;
