import { cn } from "@/utils/utils";
import ReactCountryFlag from "react-country-flag";

import { ReactComponent as CheckIcon } from "@/assets/icons/check.svg";

interface CountryItemProps {
  countryCode: string;
  country: string;
  currency: string;
  selected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const CountryItem = ({
  country,
  countryCode,
  currency,
  selected = false,
  onClick,
  disabled,
}: CountryItemProps) => {
  return (
    <div
      className={cn("flex items-center text-sm ps-6 pe-4 gap-5 group w-full", {
        "pointer-events-none cursor-wait opacity-50": disabled,
        "cursor-pointer": !disabled,
      })}
      onClick={onClick}
      aria-label={`Select ${currency}`}
    >
      <div className="size-6 rounded-full overflow-hidden">
        <ReactCountryFlag
          countryCode={countryCode}
          className="object-cover !size-full"
          svg
        />
      </div>
      <div className="space-x-2 truncate">
        <span
          className={cn({
            "group-hover:text-accent-blue dark:group-hover:text-accent-main":
              !selected,
            "text-accent-blue dark:text-accent-main": selected,
          })}
        >
          {currency}
        </span>
        <span
          className={cn("text-tertiary-gray truncate", {
            "text-accent-blue dark:text-accent-main": selected,
          })}
        >
          {country}
        </span>
      </div>
      {selected && (
        <CheckIcon className="text-accent-blue dark:text-accent-main ms-auto" />
      )}
    </div>
  );
};

export default CountryItem;
