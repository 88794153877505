import { twMerge } from "tailwind-merge";
import { Dialog, DialogPanel, Transition } from "@headlessui/react";

import { IoCloseOutline } from "react-icons/io5";

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  closeIconClassName?: string;
  closeIconSize?: number;
  hideX?: boolean;
}

const Modal = ({
  open = false,
  onClose,
  children,
  className = "",
  closeIconClassName = "",
  closeIconSize = 32,
  hideX = false,
  ...props
}: ModalProps) => {
  return (
    <Transition
      show={open}
      enter="duration-200 ease-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="duration-300 ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog onClose={onClose} className="relative z-50">
        <div
          className="fixed inset-0 bg-black/55 dark:bg-tertiary-950/55"
          aria-hidden="true"
        />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel
            className={twMerge(
              "w-full max-w-lg bg-white dark:bg-black p-6 sm:p-16 rounded-2xl shadow-2xl relative overflow-y-auto",
              className || ""
            )}
            {...props}
          >
            {!hideX && (
              <button
                className={`text-tertiary-gray hover:text-tertiary-main absolute top-4 end-4 z-10 ${closeIconClassName}`}
                onClick={onClose}
              >
                <IoCloseOutline size={closeIconSize} />
              </button>
            )}
            {children}
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
