import { useEffect, useState } from "react";
import {
  isLoggedInSelector,
  logout,
  setCurrency,
  updateUserInfo,
  userSelector,
} from "@/redux/App";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { resetCart } from "@/redux/Cart";
import { useRequest } from "./useRequest";
import { Currency } from "@/shared/types/currencies";

const useFetchUserInfo = () => {
  const [loading, setLoading] = useState(true);
  const isLoggedIn = useAppSelector((state) => isLoggedInSelector(state));
  const user = useAppSelector((state) => userSelector(state));
  const dispatch = useAppDispatch();
  const { sendRequest } = useRequest();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const res = await sendRequest({
          method: "GET",
          url: "Accounts/UserInfo",
        });
        if (res.data?.id) {
          dispatch(
            updateUserInfo({
              id: res.data?.id,
              email: res.data?.email,
              fullName: res.data?.fullName,
              bankSetup: res.data?.bankSetup ?? false,
              categorySetup: res.data?.categorySetup ?? false,
              profilePicture: res.data?.profilePicture ?? null,
              roles: res.data?.role?.$values ?? [],
            })
          );
          // Set currency
          dispatch(setCurrency(res.data?.preferredCurrency as Currency));
          setLoading(false);
        }
      } catch (error) {
        dispatch(logout());
        dispatch(resetCart());
        setLoading(false);
      }
    };

    if (isLoggedIn && !user.id) {
      setLoading(true);
      fetchUserInfo();
    } else {
      setLoading(false);
    }
  }, [dispatch, isLoggedIn, sendRequest, user]);

  return { loading };
};

export default useFetchUserInfo;
