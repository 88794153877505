import { useAppSelector } from "@/redux/hooks";
import { isLoggedInSelector } from "@/redux/App";
import { Navigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

interface GuestRouteProps {
  element: JSX.Element;
}

const GuestRoute: React.FC<GuestRouteProps> = ({ element }) => {
  const isLoggedIn = useAppSelector((state) => isLoggedInSelector(state));
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      setShouldNavigate(true);
    }
  }, [isLoggedIn]);

  if (shouldNavigate) {
    return (
      <Navigate to="/creator-dashboard" replace state={{ from: location }} />
    );
  }

  return element;
};

export default GuestRoute;
