import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "@/components/Footer";
import Header from "@/components/Header";

const HeroLayout = () => {
  const heroRef = useRef();
  const headerRef = useRef(null);
  const sentinelRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      {
        threshold: 0,
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    const ref = sentinelRef;
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [pathname]);

  return (
    <>
      <div ref={sentinelRef} className="h-0"></div>
      {isSticky && (
        <Header
          className="fixed bg-white/35 backdrop-blur-sm dark:dark:bg-[#181818]/70 z-40 origin-top !h-auto !py-3 top-0 left-0"
          searchBar={false}
          invertedColors={false}
        />
      )}
      {!isSticky && (
        <Header
          className="absolute top-0 left-0 z-40 dark:shadow-none h-[80px]"
          searchBar={false}
          invertedColors
          invertLogo
          ref={headerRef}
          invertLoginColor
        />
      )}
      <Outlet context={[heroRef]} />
      <Footer />
    </>
  );
};

export default HeroLayout;
