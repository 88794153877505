export const maskEmail = (email: string) => {
  let [username, domain] = email.split("@");
  if (username.length < 2) {
    return email;
  }
  let maskedUsername =
    username.charAt(0) +
    "*".repeat(username.length - 2) +
    username.charAt(username.length - 1);
  return maskedUsername + "@" + domain;
};

export const getUTCDateWithAddedMonth = (): Date => {
  const now = new Date();

  const utcYear = now.getUTCFullYear();
  const utcMonth = now.getUTCMonth();
  const utcDate = now.getUTCDate();
  const utcHours = now.getUTCHours();
  const utcMinutes = now.getUTCMinutes();
  const utcSeconds = now.getUTCSeconds();

  const currentUTCDate = new Date(
    Date.UTC(utcYear, utcMonth, utcDate, utcHours, utcMinutes, utcSeconds)
  );

  currentUTCDate.setUTCMonth(currentUTCDate.getUTCMonth() + 1);

  return currentUTCDate;
};

export const getBaseDomain = (url) => {
  try {
    url = url.replace(/^(https?:\/\/)?(www\.)?/, "");
    const parts = url.split("/")[0].split(".");
    const domain =
      parts.length > 2 ? parts.slice(-2).join(".") : parts.join(".");

    return domain;
  } catch (error) {
    return url;
  }
};

export const getCurrentUrl = () => {
  return window.location.href;
};

export const ensureUrlPrefix = (url) => {
  const pattern = /^(https?:\/\/)/;

  if (!pattern.test(url)) {
    return `https://${url || ""}`;
  }

  return url;
};

export const ensureUsernamePrefix = (
  username: string,
  prefix: string | number = "@"
) => {
  const firstChar = username.charAt(0);
  if (firstChar !== prefix) {
    return prefix + username;
  }
  return username;
};

export const formatTechnicalSpecs = (
  make: string | null | undefined,
  model: string | null | undefined,
  focalLength: string | null | undefined,
  flash: string | null | undefined,
  exposure: string | null | undefined
) => {
  const output: string[] = [];
  if (make) output.push(make);
  if (model) output.push(model);
  if (focalLength) output.push(`${focalLength}mm`);
  if (flash) output.push(flash);
  if (exposure) output.push(`${exposure} focus`);
  return output.join(", ");
};

// Credit: https://stackoverflow.com/a/65586375
export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = (error) => reject(error);
  });
};

// Credit: https://stackoverflow.com/a/29390393
export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return theBlob as File;
};

export const resizeImage = (file, maxWidth, maxHeight, callback) => {
  const img = new Image();
  const objectUrl = URL.createObjectURL(file);

  img.src = objectUrl;

  img.onload = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;
    if (ctx) {
      ctx.drawImage(img, 0, 0, width, height);
    }

    canvas.toBlob(
      (blob) => {
        if (blob) {
          callback(URL.createObjectURL(blob));
        } else {
          callback(canvas.toDataURL());
        }
        URL.revokeObjectURL(objectUrl);
      },
      "image/jpeg",
      1
    );
  };

  img.onerror = () => {
    URL.revokeObjectURL(objectUrl);
  };
};

export const formatPrice = (
  amount: number,
  currency = "USD",
  leading: number = 2
) => {
  try {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: leading,
      maximumFractionDigits: leading,
    }).format(amount);
  } catch (error) {
    return amount;
  }
};

// Credit: https://stackoverflow.com/a/16245768
export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return URL.createObjectURL(blob);
};

// Credit: https://stackoverflow.com/a/18650828
export const bytesToSize = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const formatNumber = (number: number) => {
  return new Intl.NumberFormat().format(number);
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);
