import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import clsx from "clsx";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Menu from "./components/Menu";

const SettingsLayout = () => {
  const [menu, showMenu] = useState(true);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Header className="fixed bg-white/35 backdrop-blur-sm dark:dark:bg-[#181818]/70 z-40 origin-top !py-3 top-0 left-0 shadow-lg shadow-tertiary-gray/5" />

      <div className="container flex max-md:flex-col gap-6 mt-[116px]">
        <Menu showMenu={menu} onToggle={() => showMenu(!menu)} />
        <div
          className={clsx("w-full", {
            "max-md:hidden": menu,
          })}
        >
          <Outlet context={[menu, showMenu]} />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SettingsLayout;
