import { cn } from "@/utils/utils";
import { Switch, Field, Label } from "@headlessui/react";

interface ToggleProps {
  active?: boolean;
  onChange?: () => void;
  label?: React.ReactNode | string;
  className?: string;
  pillClassName?: string;
  inverted?: boolean;
}

const Toggle = ({
  active = false,
  label,
  onChange = () => {},
  className = "",
  pillClassName = "",
  inverted = false,
}: ToggleProps) => {
  return (
    <Field className={className}>
      <Label>{label}</Label>
      <Switch
        checked={active}
        onChange={onChange}
        className={cn(
          pillClassName,
          "relative inline-flex h-5 w-10 items-center rounded-full",
          {
            "bg-tertiary-light-gray dark:bg-tertiary-dark-gray":
              (!active && !inverted) || (active && inverted),
            "bg-accent-blue dark:bg-accent-main":
              (active && !inverted) || (!active && inverted),
          }
        )}
      >
        <span className="sr-only">{label}</span>
        <span
          className={`${
            active ? "translate-x-[22px]" : "translate-x-[3px]"
          } inline-block h-[14px] w-[14px] transform rounded-full bg-white transition`}
        />
      </Switch>
    </Field>
  );
};

export default Toggle;
