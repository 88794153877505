import { useAppSelector } from "@/redux/hooks";
import { currencySelector } from "@/redux/App";
import { cartPriceSelector } from "@/redux/Cart";
import { formatPrice } from "@/utils/helpers";
import { Outlet } from "react-router-dom";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import OrderTable from "./components/OrderTable";
import Button from "@/components/Button";
import Divider from "@/components/Divider";

const OrderLayout = () => {
  const currency = useAppSelector(currencySelector);
  const price = useAppSelector(cartPriceSelector);

  return (
    <>
      <Header className="mb-10 sm:mb-[60px] shadow-lg shadow-gray-50 dark:shadow-none" />
      <main className="container">
        <div className="space-y-[62px] sm:space-y-[88px]">
          <Outlet />
          <div className="space-y-2">
            <h4 className="max-sm:text-lg font-semibold ms-3">
              Purchase details
            </h4>
            <OrderTable />
          </div>
        </div>
        <Divider gradient className="mt-[24px] mb-[15px] max-sm:hidden" />
        <div className="flex max-sm:flex-col justify-between items-center gap-10 max-sm:mt-[24px]">
          <div className="font-medium sm:ms-8 max-sm:flex justify-between max-sm:w-full">
            <span>Total:</span>{" "}
            <span>
              {currency?.currency || "AED"}{" "}
              {formatPrice(price.finalPrice * (currency?.rate || 1))}
            </span>
          </div>
          <div className="flex max-sm:w-full max-sm:flex-col items-center justify-end gap-2">
            <Button
              href="/cart"
              variant="transparent"
              className="max-sm:order-2 max-sm:w-full !px-3"
            >
              Back
            </Button>
            <Button href="/" variant="blue" className="max-sm:w-full !px-5">
              Continue shopping
            </Button>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default OrderLayout;
