import { format, addDays } from "date-fns";

export const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    return format(date, "MMMM d, yyyy");
  } catch (error) {
    return "Unknown date";
  }
};

export const addDaysToDate = (days: number = 1) => {
  return addDays(new Date(), days);
};

export const getToday = () => {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
