import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import Header from "@/components/Header";
import UserCard from "./components/UserCard";
import Divider from "./components/Divider";
import SideMenu from "./components/SideMenu";
import Footer from "@/components/Footer";

const CreatorDashboardLayout = () => {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Header className="fixed bg-white/35 backdrop-blur-sm dark:dark:bg-[#181818]/70 z-40 origin-top !py-3 top-0 left-0 shadow-lg shadow-tertiary-gray/5" />
      <main className="container max-w-full max-lg:flex-col flex gap-6 xl:gap-[46px] mt-[116px] lg:mt-[128px]">
        <aside className="lg:w-[280px] shrink-0 space-y-6">
          <UserCard />
          <Divider className="!my-0 max-lg:max-w-[250px] lg:hidden" />
          <SideMenu />
        </aside>

        <div className="flex-1 min-h-[auto] min-w-[auto]">
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default CreatorDashboardLayout;
