import { useEffect } from "react";
import { themeSelector } from "@/redux/App";
import { useAppSelector } from "@/redux/hooks";

const useThemeToggle = (checkForDarkMode: boolean = true) => {
  const theme = useAppSelector((state) => themeSelector(state));
  const isDarkMode =
    (theme === "system" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches) ||
    theme === "dark";

  useEffect(() => {
    if (checkForDarkMode) {
      if (isDarkMode) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    }
  }, [theme, checkForDarkMode, isDarkMode]);

  return {
    isDarkMode,
  };
};

export default useThemeToggle;
