import Button from "components/Button";

// Redux
import { themeSelector } from "@/redux/App";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

// Icons
import { FiMoon, FiSun } from "react-icons/fi";

const ThemeToggler = () => {
  const theme = useAppSelector((state) => themeSelector(state));
  const dispatch = useAppDispatch();

  const handleToggleTheme = () => {
    dispatch({
      type: "app/toggleTheme",
      payload:
        (theme === "system" &&
          window.matchMedia("(prefers-color-scheme: dark)").matches) ||
        theme === "dark"
          ? "light"
          : "dark",
    });
  };

  return (
    <Button
      variant="default-dimmed"
      tiny={true}
      icon={
        (theme === "system" &&
          window.matchMedia("(prefers-color-scheme: dark)").matches) ||
        theme === "dark"
          ? FiSun
          : FiMoon
      }
      onClick={handleToggleTheme}
    ></Button>
  );
};

export default ThemeToggler;
