import useOfflineCheck from "@/hooks/useOfflineCheck";

const OfflineAlert = () => {
  const isOffline = useOfflineCheck();

  if (!isOffline) return <></>;

  return (
    <div className="fixed bottom-0 w-full bg-red-500 text-white z-50 py-2 px-6 flex items-center justify-center gap-4">
      <strong>You're offline!</strong>
      <span>Please connect to the Internet to use Mohtawa.</span>
    </div>
  );
};

export default OfflineAlert;
