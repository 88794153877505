interface CircleLoaderProps {
  className?: string;
  ringClassName?: string;
  progress: number;
}

const CircleLoader = ({
  className = "",
  ringClassName = "",
  progress = 0,
}: CircleLoaderProps) => {
  return (
    <div className={`relative ${className}`}>
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          className="text-[#D9D9D9] dark:text-white stroke-current"
          strokeWidth="9"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
        ></circle>
        <circle
          className={`text-accent-blue dark:text-accent-main progress-ring__circle stroke-current ${ringClassName}`}
          strokeWidth="9"
          strokeLinecap="round"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
          strokeDasharray="251.2"
          strokeDashoffset={`calc(251.2px - (251.2px * ${progress}) / 100)`}
        ></circle>
      </svg>
    </div>
  );
};

export default CircleLoader;
