import React, { createContext, useContext, useRef } from "react";

interface AbortControllerContextType {
  abortController: React.MutableRefObject<AbortController | null>;
}

const AbortControllerContext = createContext<
  AbortControllerContextType | undefined
>(undefined);

export const AbortControllerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const abortController = useRef<AbortController | null>(new AbortController());

  return (
    <AbortControllerContext.Provider value={{ abortController }}>
      {children}
    </AbortControllerContext.Provider>
  );
};

export const useAbortController = () => {
  const context = useContext(AbortControllerContext);
  if (!context) {
    throw new Error(
      "useAbortController must be used within an AbortControllerProvider"
    );
  }
  return context.abortController;
};
