import { cartItemsSelector } from "@/redux/Cart";
import { useAppSelector } from "@/redux/hooks";
import { getToday } from "@/utils/date";
import { formatPrice } from "@/utils/helpers";
import { currencySelector } from "@/redux/App";
import { Link } from "react-router-dom";
import CartImage from "@/components/Cart/CartImage";

const OrderTable = () => {
  const products = useAppSelector(cartItemsSelector);
  const currency = useAppSelector(currencySelector);

  return (
    <div className="space-y-4">
      <div className="bg-tertiary-50 dark:bg-tertiary-dark-gray rounded-xl max-md:px-3 max-md:py-4 md:p-4 flex gap-2 items-center">
        <div className="basis-10/12 lg:basis-[30%] ps-2 lg:ps-4 text-[#475156] dark:text-white text-xs uppercase">
          Assets
        </div>
        <div className="basis-[10%] text-[#475156] dark:text-white text-xs uppercase max-lg:hidden">
          Price
        </div>
        <div className="basis-[10%] text-[#475156] dark:text-white text-xs uppercase max-lg:hidden">
          Resolution
        </div>
        <div className="basis-[10%] text-[#475156] dark:text-white text-xs uppercase max-lg:hidden">
          Format
        </div>
        <div className="basis-[10%] text-[#475156] dark:text-white text-xs uppercase max-lg:hidden">
          #ID
        </div>
        <div className="basis-[10%] text-[#475156] dark:text-white text-xs uppercase max-lg:hidden">
          Licensing
        </div>
        <div className="basis-[10%] text-[#475156] dark:text-white text-xs uppercase max-lg:hidden">
          Duration
        </div>
        <div className="basis-[10%] text-[#475156] dark:text-white text-xs uppercase max-lg:hidden">
          Date
        </div>
      </div>

      {products?.map((product) => (
        <div
          className="px-3 md:px-4 flex gap-2 items-center"
          key={product.productId}
        >
          <div className="basis-10/12 lg:basis-[30%] ps-2 lg:ps-4 text-[#191C1F] dark:text-white text-sm flex items-center gap-3 overflow-hidden">
            <CartImage
              src={product.fileUrl}
              title={product?.title ?? "Product"}
              className="w-[90px] h-[70px] md:w-[100px] md:h-[70px]"
            />
            <div className="max-lg:flex max-lg:flex-col max-lg:gap-1.5 max-lg:py-1 truncate">
              <span className="max-lg:font-medium truncate text-base lg:text-sm">
                <Link
                  to={`/product/${product.productId}`}
                  className="unstyled-link font-medium"
                >
                  {product.title}
                </Link>
              </span>
              <span className="lg:hidden text-tertiary-gray text-sm">
                {currency?.currency || "AED"}{" "}
                {formatPrice((product?.price ?? 0) * (currency?.rate || 1))}
              </span>
              <span className="lg:hidden text-tertiary-gray text-sm">
                #{product.sku}
              </span>
            </div>
          </div>
          <div className="basis-[10%] text-sm font-medium max-lg:hidden">
            {currency?.currency || "AED"}{" "}
            {formatPrice((product?.price ?? 0) * (currency?.rate || 1))}
          </div>
          <div className="basis-[10%] text-sm font-medium max-lg:hidden">
            N/A
          </div>
          <div className="basis-[10%] text-sm font-medium max-lg:hidden uppercase">
            {product.type?.split("/")?.[1]}
          </div>
          <div className="basis-[10%] text-sm font-medium max-lg:hidden">
            #{product.sku}
          </div>
          <div className="basis-[10%] text-sm font-medium max-lg:hidden">
            <span className="font-medium text-sm">N/A</span>
          </div>
          <div className="basis-[10%] text-sm font-medium max-lg:hidden">
            N/A
          </div>
          <div className="basis-[10%] text-sm font-medium max-lg:hidden">
            {getToday()}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderTable;
