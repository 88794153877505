import { useEffect, useState } from "react";
import { useAppDispatch } from "@/redux/hooks";
import { setIsUploading } from "@/redux/Upload";
import { cn } from "@/utils/utils";
import { bytesToSize } from "@/utils/helpers";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useAbortController } from "@/contexts/AbortControllerContext";

// Icons
import { ReactComponent as DocumentIcon } from "@/assets/icons/misc/document_text.svg";
import { ReactComponent as TrashIcon } from "@/assets/icons/trash.svg";
import { ReactComponent as CheckIcon } from "@/assets/icons/checks/check_circle.svg";
import CancelModal from "./CancelModal";

interface UploaderProps {
  filename: string;
  size: number;
  progress: number;
  className?: string;
  failed?: boolean;
  onDelete?: () => void;
}

const Uploader = ({
  filename,
  size,
  progress,
  className,
  failed = false,
  onDelete,
}: UploaderProps) => {
  const dispatch = useAppDispatch();
  const [progressBar, showProgressBar] = useState(true);
  const [cancelModal, showCancelModal] = useState(false);
  const abortController = useAbortController();

  const handleAbort = () => {
    abortController.current?.abort();
    dispatch(setIsUploading(false));
  };

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        showProgressBar(false);
      }, 1000);
    }
  }, [progress]);

  return (
    <>
      <CancelModal
        show={cancelModal}
        onClose={() => showCancelModal(false)}
        onNext={onDelete ? onDelete : handleAbort}
      />
      <div
        className={cn(
          "w-full bg-tertiary-50 dark:bg-tertiary-dark-gray rounded-md p-4 space-y-2",
          className
        )}
      >
        <div className="flex justify-between items-start">
          <div className="flex items-start gap-3">
            <DocumentIcon
              className={cn({
                "text-tertiary-gray": !failed,
                "text-[#FF0000]": failed,
              })}
            />
            <div className="space-y-0.5">
              <p
                className={cn("text-sm font-medium", {
                  "text-[#FF0000]": failed,
                  "text-[#353535] dark:text-white": !failed,
                })}
              >
                {failed ? "Upload failed, please try again" : filename}
              </p>
              <p
                className={cn("text-xs", {
                  "text-[#989692]": !failed,
                  "text-[#FF0000]": failed,
                })}
              >
                {failed ? filename : bytesToSize(size)}
              </p>
              {!progressBar && (
                <Link to="/upload" className="font-semibold text-sm">
                  Click to view
                </Link>
              )}
              {failed && (
                <p className="text-sm font-semibold text-[#FF0000]">
                  Try again
                </p>
              )}
            </div>
          </div>
          {progress === 100 && progressBar ? (
            <CheckIcon className="text-[#50C878]" />
          ) : (
            <button aria-label="Cancel" onClick={() => showCancelModal(true)}>
              <TrashIcon
                className={cn("size-[20px]", {
                  "text-[#FF0000]": failed,
                  "text-tertiary-gray": !failed,
                })}
              />
            </button>
          )}
        </div>
        <AnimatePresence initial={false}>
          {!failed && progressBar && (
            <motion.div
              initial={{ opacity: 100 }}
              exit={{ opacity: 0 }}
              className="flex items-center gap-3"
            >
              <div className="flex-1 min-w-[auto] h-[6px] bg-tertiary-50 dark:bg-[#181818] rounded-xl overflow-clip relative">
                <div
                  className={cn("h-full rounded-xl", {
                    "bg-accent-blue dark:bg-accent-main": progress < 100,
                    "bg-[#50C878]": progress === 100,
                  })}
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <span className="text-[#353535] dark:text-white text-xs">
                {progress}%
              </span>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default Uploader;
