import { SignupLayoutProps } from "..";
import Slider from "react-slick";
import CarouselPill from "./CarouselPill";

// Images
import signup_bg from "@/assets/images/auth/signup_bg.webp";
import become_creator_bg from "@/assets/images/become_creator_bg.webp";

const Side = ({ creatorPage = false }: SignupLayoutProps) => {
  return (
    <section
      className={`${
        creatorPage
          ? "lg:w-[49%] max-lg:h-[18.5rem] lg:min-h-screen lg:py-10 lg:gap-10"
          : "lg:w-[46%] max-lg:h-[17.25rem] lg:min-h-screen lg:py-5 lg:gap-10"
      } bg-cover bg-center flex-grow flex flex-col gap-5 py-6 md:justify-end text-white`}
      style={{
        backgroundImage: `url(${creatorPage ? become_creator_bg : signup_bg})`,
      }}
    >
      <h1
        className={`text-xl ${
          creatorPage
            ? "lg:text-[2.5rem] lg:leading-[2.938rem] lg:px-10"
            : "lg:text-[40px] lg:leading-[2.938rem] lg:px-[52px]"
        } font-bold leading-[1.563rem] px-6 mt-auto`}
      >
        {creatorPage ? (
          <>
            Share your content
            <br />
            with people, and <br className="max-sm:hidden" />
            gain{" "}
            <span className="bg-gradient-to-r from-[#418FE8] to-[#8A69CC] dark:from-accent-main dark:to-accent-main bg-clip-text text-transparent">
              recognition
            </span>{" "}
            in <br className="max-sm:hidden" />
            no time!
          </>
        ) : (
          <>
            Get unlimited
            <br /> cultural content. And
            <br /> join{" "}
            <span className="bg-gradient-to-r from-[#418FE8] to-[#8A69CC] dark:from-accent-main dark:to-accent-main bg-clip-text text-transparent">
              Mohtawa
            </span>{" "}
            now!
          </>
        )}
      </h1>
      <div className="slider-container">
        <Slider
          infinite={true}
          arrows={false}
          variableWidth={true}
          autoplay={true}
          speed={10000}
          autoplaySpeed={0}
          centerMode={true}
          cssEase="linear"
          adaptiveHeight={true}
        >
          <CarouselPill>Culture</CarouselPill>
          <CarouselPill>Content</CarouselPill>
          <CarouselPill>Community</CarouselPill>
          <CarouselPill>Technology</CarouselPill>
        </Slider>
      </div>
    </section>
  );
};

export default Side;
