import { Link } from "react-router-dom";

interface MenuLinkProps {
  to?: string;
  smallText?: boolean;
  children: React.ReactNode;
}

const MenuLink = ({ to = "", smallText = true, children }: MenuLinkProps) => {
  return (
    <Link
      to={to}
      className={`unstyled-link text-tertiary-main hover:text-primary-main dark:hover:text-accent-main shrink-0 hover:no-underline ${
        smallText ? "text-xs" : "text-sm"
      }`}
    >
      {children}
    </Link>
  );
};

export default MenuLink;
