import { FunctionComponent, LegacyRef, SVGProps, forwardRef } from "react";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import CircleLoader from "./Loaders/CircleLoader";

export type ButtonVariants =
  | "default"
  | "default-dimmed"
  | "tertiary"
  | "blue"
  | "gray"
  | "white"
  | "light"
  | "muted"
  | "danger"
  | "transparent"
  | "transparent-inverted"
  | "link"
  | "link-unstyled"
  | "gradient"
  | "gradient-default";

export type ButtonStyles = "filled" | "outlined";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asLink?: boolean;
  icon?: IconType | FunctionComponent<SVGProps<SVGSVGElement>>;
  iconEnd?: IconType | FunctionComponent<SVGProps<SVGSVGElement>>;
  children?: React.ReactNode;
  href?: string;
  variant?: ButtonVariants;
  buttonStyle?: ButtonStyles;
  tiny?: boolean;
  rounded?: boolean;
  iconClassName?: string;
  loading?: boolean;
}

const Button = forwardRef(
  (
    {
      asLink = false,
      variant = "default",
      buttonStyle = "filled",
      children,
      icon: ButtonIcon,
      iconEnd: ButtonIconEnd,
      href,
      tiny = false,
      rounded = true,
      iconClassName = "",
      loading = false,
      ...props
    }: ButtonProps,
    ref: LegacyRef<HTMLButtonElement> | undefined
  ) => {
    const buttonVariantClass: Record<
      ButtonStyles,
      Record<ButtonVariants, string>
    > = {
      filled: {
        default:
          "bg-black dark:bg-white text-white dark:text-black dark:after:bg-black after:bg-white hover:text-black dark:hover:text-white hover:bg-transparent",
        "default-dimmed":
          "bg-tertiary-925/40 text-white after:bg-white hover:text-black hover:bg-transparent",
        blue: "bg-accent-blue hover:!bg-accent-blue/90 dark:bg-accent-main dark:hover:!bg-accent-main/90 text-white dark:text-black dark:after:bg-white after:bg-black hover:text-white dark:hover:text-black hover:bg-transparent",
        gray: "bg-tertiary-gray hover:!bg-tertiary-dark-gray text-white dark:after:bg-white after:bg-black hover:text-white dark:hover:text-black hover:bg-transparent",
        white:
          "bg-white dark:bg-tertiary-950 text-black dark:text-white dark:after:bg-white after:bg-black hover:text-white dark:hover:text-black hover:bg-transparent",
        transparent: "after:bg-black hover:text-white",
        "transparent-inverted": "after:bg-white text-white hover:text-black",
        light:
          "bg-gray-100 dark:bg-tertiary-950 text-black dark:text-white after:bg-white hover:text-black hover:bg-transparent",
        link: "inline-flex link !p-0 hover:!underline",
        "link-unstyled": "inline-flex !p-0 hover:!underline",
        gradient: "after:opacity-25 dark:after:bg-black after:bg-white",
        "gradient-default":
          "after:opacity-25 dark:after:bg-black after:bg-white bg-gradient-to-r from-[#D370E4] via-[#6B56DA] to-[#4C9FC0] dark:from-accent-main dark:to-accent-main text-white dark:text-black",
        tertiary:
          "bg-tertiary-button dark:bg-white text-white dark:text-black dark:after:bg-black after:bg-white hover:text-black dark:hover:text-white hover:bg-transparent",
        muted:
          "bg-tertiary-50 text-tertiary-gray dark:bg-tertiary-925/45 dark:text-white hover:bg-accent-blue hover:text-white dark:hover:bg-accent-main dark:hover:text-black",
        danger: "bg-[#F52C1F] text-white hover:after:bg-white/25",
      },
      outlined: {
        blue: "",
        default:
          "bg-transparent border border-black dark:border-white box-border after:bg-black dark:after:bg-white hover:text-white dark:hover:text-black",
        "default-dimmed":
          "bg-transparent border border-tertiary-gray text-tertiary-gray dark:text-white box-border after:bg-black dark:after:bg-white hover:text-white dark:hover:text-black",
        light: "",
        transparent: "",
        "transparent-inverted": "",
        white: "",
        gray: "",
        link: "inline-flex link !p-0 hover:!underline",
        "link-unstyled": "inline-flex !p-0 hover:!underline",
        gradient: "",
        "gradient-default": "",
        tertiary: "",
        muted: "",
        danger: "",
      },
    };

    const buttonClass = twMerge(
      props.className || "",
      "relative text-center font-medium relative z-0 after:w-full after:h-full after:absolute after:top-0 after:right-0 after:scale-0 hover:after:scale-100 after:transition-transform after:duration-500 after:origin-top-right after:-z-10 transition-all duration-500 overflow-hidden flex justify-center items-center gap-2 sm:gap-4 hover:no-underline disabled:opacity-50 disabled:cursor-not-allowed unstyled-link disabled:cursor-not-allowed disabled:opacity-50",
      buttonVariantClass?.[buttonStyle]?.[variant],
      clsx({
        "rounded-full after:rounded-full":
          rounded && variant !== "link" && variant !== "link-unstyled",
        "rounded-xl after:rounded-xl": !rounded,
        "size-[2.25rem] flex items-center justify-center": tiny,
        "px-8 py-[0.875rem]": !tiny,
        "cursor-not-allowed": loading,
      })
    );

    const ButtonContent = (
      <>
        {ButtonIcon && (
          <ButtonIcon
            className={`shrink-0 pointer-events-none ${iconClassName}`}
            size={24}
          />
        )}
        {children}
        {ButtonIconEnd && (
          <ButtonIconEnd
            className={`shrink-0 pointer-events-none ${iconClassName}`}
            size={24}
          />
        )}
      </>
    );

    return href ? (
      <Link to={href} className={buttonClass}>
        {ButtonContent}
      </Link>
    ) : (
      <button
        {...props}
        className={buttonClass}
        disabled={loading ? true : props.disabled}
        ref={ref}
      >
        {ButtonContent}
        {loading && (
          <span className="size-full absolute inset-0 flex items-center justify-center bg-white dark:bg-tertiary-950">
            <CircleLoader progress={50} className="size-8 animate-spin" />
          </span>
        )}
      </button>
    );
  }
);

export default Button;
