import { Countries } from "../BecomeCreator";
import { RootState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SettingsState {
  countries: Countries;
}

const initialState: SettingsState = {
  countries: [],
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    initializeCountries: (state, action: PayloadAction<Countries>) => {
      state.countries = action.payload;
    },
  },
});

export const { initializeCountries } = settingsSlice.actions;

export const countriesSelector = (state: RootState) =>
  state.settingsSlice.countries;

export default settingsSlice.reducer;
