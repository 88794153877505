import { sendRequest } from "@/utils/sendRequest";

export const fetchUserInfo = async () => {
  try {
    const res = await sendRequest({
      method: "GET",
      url: "Users",
    });
    const data = await res.data;
    return data?.data || [];
  } catch (error) {
    console.error(error);
  }
};
