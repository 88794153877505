import { configureStore } from "@reduxjs/toolkit";
import { appSlice } from "./App";
import { signupAuthSlice } from "./Auth/signup";
import { becomeCreatorSlice } from "./BecomeCreator";
import { uploadSlice } from "./Upload";
import { settingsSlice } from "./Settings";
import { productSlice } from "./Product";
import { cartSlice } from "./Cart";
import { subscriptionSlice } from "./SubscriptionPlan";

export const store = configureStore({
  reducer: {
    appSlice: appSlice.reducer,
    signupAuthSlice: signupAuthSlice.reducer,
    becomeCreatorSlice: becomeCreatorSlice.reducer,
    uploadSlice: uploadSlice.reducer,
    settingsSlice: settingsSlice.reducer,
    productSlice: productSlice.reducer,
    cartSlice: cartSlice.reducer,
    subscriptionSlice: subscriptionSlice.reducer,
  },
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
