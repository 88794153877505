import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { languageSelector } from "@/redux/App";
import { useAppSelector } from "@/redux/hooks";

const useLanguage = () => {
  const lang = useAppSelector((state) => languageSelector(state));
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang ?? "en");
    if (lang === "ar") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [lang, i18n]);
};

export default useLanguage;
