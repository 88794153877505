import { Suspense } from "react";
import { router } from "./routes";
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import UserInfoProvider from "./providers/UserInfoProvider";

import { useFetchCart } from "./hooks/useFetchCart";
import useThemeToggle from "./hooks/useThemeToggle";
import useLanguage from "./hooks/useLanguage";

import { AbortControllerProvider } from "./contexts/AbortControllerContext";
import { CookiesProvider } from "react-cookie";
import { Toaster } from "react-hot-toast";
import PageLoader from "./components/PageLoader";
import StickyBug from "./components/StickyBug";
import OfflineAlert from "./components/OfflineAlert";

const App = () => {
  useThemeToggle();
  useLanguage();
  useFetchCart();

  return (
    <Suspense fallback={<PageLoader />}>
      <CookiesProvider>
        <HelmetProvider>
          <Toaster
            toastOptions={{
              className:
                "!bg-white dark:!bg-black dark:!text-white !shadow-xl !rounded-2xl",
            }}
          />
          <OfflineAlert />
          <StickyBug />
          <AbortControllerProvider>
            <UserInfoProvider>
              <RouterProvider router={router} />
            </UserInfoProvider>
          </AbortControllerProvider>
        </HelmetProvider>
      </CookiesProvider>
    </Suspense>
  );
};

export default App;
