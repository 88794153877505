import { useState, useRef, useEffect } from "react";
import { useAppSelector } from "@/redux/hooks";
import { isLoggedInSelector } from "@/redux/App";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Category } from "@/shared/types/categories";
import { Popover, PopoverPanel, Transition } from "@headlessui/react";
import { cn } from "@/utils/utils";
import Input from "@/components/Input";
import ContentFilter from "@/components/Filters/ContentFilter";
import RecentSearches from "./components/RecentSearches";

import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";

interface SearchProps {
  className?: string;
  inputClassName?: string;
  hideFilterOnMobile?: boolean;
  inHeader?: boolean;
}

const Search = ({
  className = "",
  inputClassName,
  hideFilterOnMobile = false,
  inHeader = false,
}: SearchProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation("features", { keyPrefix: "search" });
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") ?? "");
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const [category, setCategory] = useState<Category>();

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim() === "") return;
    if (category && category.id !== "all") {
      return navigate(
        `/search/${category.name?.toLowerCase()}/?q=${searchTerm}`
      );
    }
    navigate(`/search?q=${searchTerm}`);
  };

  const handleFocus = () => {
    if (!isLoggedIn) return;
    setIsOpen(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (
      relatedTarget &&
      (inputRef.current?.contains(relatedTarget) ||
        popoverRef.current?.contains(relatedTarget))
    ) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      if (
        (inputRef.current?.contains(event.target as Node) ||
          popoverRef.current?.contains(event.target as Node)) &&
        isLoggedIn
      ) {
        setIsOpen(true);
      }
    };

    const handleFocusOut = (event: FocusEvent) => {
      if (
        !inputRef.current?.contains(event.relatedTarget as Node) &&
        !popoverRef.current?.contains(event.relatedTarget as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);

    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, [isLoggedIn]);

  return (
    <form className={`w-full ${className}`} onSubmit={handleSearchSubmit}>
      <Popover className="relative w-full">
        {({ open }) => (
          <>
            <Input
              placeholder={t("placeholder")}
              icon={SearchIcon}
              iconClassName={cn("text-tertiary-main max-md:size-[18px]", {
                "inset-x-[17px] inset-y-[17px] md:inset-y-[22px] md:inset-x-[18px]":
                  !inHeader,
                "inset-x-[15px] inset-y-[12px] md:inset-y-[20px] md:inset-x-[18px]":
                  inHeader,
              })}
              className={cn(
                "h-full md:ps-[55px] md:!py-[20px] placeholder:text-sm shadow-[0px_4px_20px_0px_#74747415] dark:shadow-none",
                {
                  "md:h-[58px] !py-[8px] ps-[44px]": inHeader,
                  "h-[52px] md:h-[64px] !py-[9px] ps-[40px]": !inHeader,
                },
                inputClassName
              )}
              ref={inputRef}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="search"
              autoComplete="off"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div
              className={cn("flex gap-4 items-center absolute", {
                "max-lg:hidden": hideFilterOnMobile,
                "top-[7.5px] end-[10px] md:top-[11px] md:end-3": !inHeader,
                "top-[7px] end-[7px]": inHeader,
              })}
            >
              <ContentFilter
                buttonClassName="bg-gradient-to-r from-[#8A69CC] via-[#418FE8] to-black dark:from-accent-main dark:to-accent-main text-white dark:text-black after:opacity-40 !py-[10px] md:!py-3 max-md:!pe-[6px] rounded-full !gap-2 md:!gap-4 min-w-[73px] md:min-w-[118px] justify-center !px-4 after:content-none max-md:text-xs"
                chevronClassName="size-[12px] md:size-[17px]"
                selectedOption={category}
                setSelectedOption={setCategory}
              />
            </div>
            <Transition
              show={isOpen}
              enter="duration-200 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="duration-300 ease-out"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              <PopoverPanel
                ref={popoverRef}
                className="absolute z-40 max-[420px]:left-1/2 max-[420px]:-translate-x-1/2 max-[420px]:w-screen w-full px-7 py-8 bg-white dark:bg-tertiary-950 rounded-xl shadow-xl transition translate-y-2 md:translate-y-4 space-y-8"
              >
                <RecentSearches />
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </form>
  );
};

export default Search;
