import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { AnchorProps } from "@headlessui/react/dist/internal/floating";
import { cn } from "@/utils/utils";

import { ReactComponent as ChevronDownIcon } from "@/assets/icons/chevron_down.svg";

interface DropdownProps {
  button?: React.ReactNode;
  buttonText?: string;
  className?: string;
  buttonClassName?: string;
  chevronClassName?: string;
  items?: Array<DropdownItem>;
  children?: React.ReactNode;
  anchor?: AnchorProps;
}

export type DropdownItem = {
  id: number | string;
  content: React.ReactNode | string;
};

const Dropdown = ({
  button,
  buttonText = "",
  className = "",
  buttonClassName = "",
  chevronClassName,
  items,
  children,
  anchor = "bottom end",
}: DropdownProps) => {
  return (
    <Menu>
      {({ open }) => (
        <>
          <MenuButton
            className={cn(
              {
                "flex items-center justify-end gap-2 text-sm font-medium text-tertiary-gray":
                  !button,
              },
              buttonClassName
            )}
          >
            {button ? (
              button
            ) : (
              <>
                <span>{buttonText}</span>
                <ChevronDownIcon
                  className={cn(
                    "size-[14px] shrink-0 transition-transform duration-300",
                    {
                      "-rotate-180": open,
                    },
                    chevronClassName
                  )}
                />
              </>
            )}
          </MenuButton>
          <AnimatePresence>
            {open && (
              <MenuItems
                anchor={anchor}
                static
                as={motion.div}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className={cn(
                  "bg-white dark:bg-tertiary-dark-gray px-7 py-6 rounded-xl shadow-[0px_4px_20px_0px_rgba(164,162,162,0.19)] dark:shadow-none [--anchor-gap:4px] space-y-5",
                  className
                )}
              >
                {items?.map((item) => {
                  const { id, content } = item;
                  return (
                    <MenuItem key={id} as="div" className="py-2 px-4">
                      {content}
                    </MenuItem>
                  );
                })}
                {children}
              </MenuItems>
            )}
          </AnimatePresence>
        </>
      )}
    </Menu>
  );
};

export default Dropdown;
