import { RootState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Categories = {
  id: string;
  name: string;
}[];

export type Countries = {
  $id: string;
  id: number;
  iso: string;
  name: string;
  nameAr: string;
  iso3: string;
  numCode: string;
  phoneCode: string;
  currency: string;
  currencyRate: null | string | number;
  companies: {
    $id: string;
    $values: any[];
  };
  products: {
    $id: string;
    $values: any[];
  };
  buyerToSupplierMigrations: {
    $id: string;
    $values: any[];
  };
  bankDetail: null;
}[];

export interface BecomeCreatorState {
  categories: Categories;
  countries: Countries;
  content: string[];
  uploaded_id: string;
  document_type: string;
  id_country: string;
  driver_license: string;
  account_name: string;
  bank: string;
  country: {
    id?: number;
    iso?: string;
    iso3?: string;
  };
  routing_nb: string;
  account_nb: string;
  iban: string;
  currency: string;
  swift?: string;
  company_name?: string;
  company_address?: string;
}

const initialState: BecomeCreatorState = {
  categories: [],
  countries: [],
  content: [],
  uploaded_id: "",
  document_type: "passport",
  id_country: "UAE",
  driver_license: "",
  account_name: "",
  bank: "",
  country: {
    id: 224,
    iso: "AE",
    iso3: "UAE",
  },
  routing_nb: "",
  account_nb: "",
  iban: "",
  currency: "AED",
  swift: "",
  company_name: "",
  company_address: "",
};

export const becomeCreatorSlice = createSlice({
  name: "becomeCreator",
  initialState,
  reducers: {
    initializeCategories: (state, action: PayloadAction<Categories>) => {
      state.categories = action.payload;
    },
    initializeCountries: (state, action: PayloadAction<Countries>) => {
      state.countries = action.payload;
    },
    updateContent: (state, action: PayloadAction<string>) => {
      if (state.content.includes(action.payload)) {
        state.content = state.content.filter((cat) => cat !== action.payload);
      } else {
        state.content.push(action.payload);
      }
    },
    updateFields: (
      state,
      action: PayloadAction<
        {
          field: string;
          value: string;
        }[]
      >
    ) => {
      action.payload.forEach(({ field, value }) => {
        state[field] = value;
      });
    },
    resetFields: (state) => {
      return {
        ...initialState,
        countries: state.countries,
      };
    },
  },
});

export const {
  initializeCategories,
  initializeCountries,
  updateContent,
  updateFields,
  resetFields,
} = becomeCreatorSlice.actions;

export const categoriesSelector = (state: RootState) =>
  state.becomeCreatorSlice.categories;
export const countriesSelector = (state: RootState) =>
  state.becomeCreatorSlice.countries;
export const creatorFieldsSelector = (state: RootState) =>
  state.becomeCreatorSlice;

export default becomeCreatorSlice.reducer;
