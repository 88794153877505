import { isLoggedInSelector } from "@/redux/App";
import { store } from "@/redux/store";
import { sendRequest } from "@/utils/sendRequest";
import { defer } from "react-router-dom";

export async function fetchProduct({ params }) {
  const state = store.getState();
  const isLoggedIn = isLoggedInSelector(state);
  const { productId } = params;

  try {
    const res = sendRequest({
      url: `Products/${
        isLoggedIn ? `AuthorizedProduct/${productId}` : productId
      }`,
      method: "GET",
    });
    return defer({
      productData: res
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        }),
    });
  } catch (error) {
    throw new Response("Not Found", { status: 404 });
  }
}
