import { Outlet, useLocation } from "react-router-dom";
import UploadQueue from "@/features/UploadQueue";

const RootLayout = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/upload" && <UploadQueue />}
      <Outlet />
    </>
  );
};

export default RootLayout;
