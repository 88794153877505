import Lottie, { LottieComponentProps } from "lottie-react";
import * as animationData from "@/assets/lottie/mohtawa_loading.json";

interface MohtawaLoaderProps {
  width?: number;
  className?: string;
}

const MohtawaLoader = ({ width, className }: MohtawaLoaderProps) => {
  const animationOptions: LottieComponentProps = {
    animationData: animationData,
    loop: true,
    autoPlay: true,
    style: {
      width: width,
    },
    className: className,
  };

  return <Lottie {...animationOptions} />;
};

export default MohtawaLoader;
