import {
  Popover as HUIPopover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { AnchorProps } from "@headlessui/react/dist/internal/floating";

interface PopoverProps {
  button: React.ReactNode | string;
  className?: string;
  popoverClassName?: string;
  buttonClassName?: string;
  children: React.ReactNode;
  buttonOnClick?: () => void;
  anchor?: AnchorProps;
}

const Popover = ({
  button,
  className = "",
  popoverClassName = "",
  buttonClassName = "",
  children,
  buttonOnClick,
  anchor = "bottom end",
}: PopoverProps) => {
  return (
    <HUIPopover
      className={`relative flex items-center justify-center ${popoverClassName}`}
    >
      <PopoverButton
        as={typeof button === "string" ? "button" : "div"}
        className={buttonClassName}
        onClick={buttonOnClick}
      >
        {button}
      </PopoverButton>
      <Transition
        enter="duration-200 ease-out"
        enterFrom="scale-95 opacity-0"
        enterTo="scale-100 opacity-100"
        leave="duration-300 ease-out"
        leaveFrom="scale-100 opacity-100"
        leaveTo="scale-95 opacity-0"
      >
        <PopoverPanel
          anchor={anchor}
          className={`p-5 z-40 bg-white dark:bg-tertiary-950 rounded-xl shadow-[0px_4px_20px_0px_#A4A2A219] dark:shadow-none origin-top transition ${className}`}
        >
          {children}
        </PopoverPanel>
      </Transition>
    </HUIPopover>
  );
};

export default Popover;
