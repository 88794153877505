import { useEffect } from "react";
import { useRequest } from "./useRequest";
import { isLoggedInSelector } from "@/redux/App";
import { initializeCart, setPrices } from "@/redux/Cart";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useQuery } from "@tanstack/react-query";
import { CartItem } from "@/shared/types/cartItem";

type SuccessResponse = {
  $id: string;
  data?: {
    $id: string;
    cartId: string;
    userId: string;
    cartStatus: number;
    products: {
      $id: string;
      $values: CartItem[];
    };
    originalPrice: number;
    finalPrice: number;
    couponCode?: string | null;
    couponPercentageOff: number;
  };
};

export const useFetchCart = () => {
  const { sendRequest } = useRequest();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(isLoggedInSelector);

  const fetchCart = async () => {
    try {
      const res = await sendRequest({
        method: "GET",
        url: "ShoppingCart",
      });
      const data: SuccessResponse = await res.data;
      return data.data;
    } catch (error) {
      console.error(error);
    }
  };

  const { data: cartData } = useQuery<SuccessResponse["data"]>({
    queryKey: ["cart"],
    queryFn: fetchCart,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (cartData) {
      const {
        cartId,
        products,
        originalPrice,
        finalPrice,
        couponCode,
        couponPercentageOff,
      } = cartData;

      // Register cart ID and products
      dispatch(
        initializeCart({
          id: cartId,
          items: products.$values,
        })
      );
      // Register prices
      dispatch(
        setPrices({
          originalPrice: originalPrice,
          finalPrice: finalPrice,
          couponCode: couponCode || "",
          discountPercentage: couponPercentageOff,
        })
      );
    }
  }, [cartData, dispatch]);
};
