interface DividerProps {
  className?: string;
}

const Divider = ({ className = "" }: DividerProps) => {
  return (
    <div
      className={`w-[89%] mx-auto h-[1px] bg-gradient-to-r from-[#CACACA] via-[#646464] to-[#CACACA] dark:from-[#646464] dark:via-[#CACACA] dark:to-[#646464] ${className}`}
    ></div>
  );
};

export default Divider;
