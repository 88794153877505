import { FunctionComponent, SVGProps } from "react";
import { Link } from "react-router-dom";

interface TabButtonProps {
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  href: string;
  active?: boolean;
  children: React.ReactNode;
}

const TabButton = ({
  icon: TabIcon,
  href,
  active = false,
  children,
}: TabButtonProps) => {
  return (
    <Link
      to={href}
      className={`text-sm inline-flex gap-2 lg:gap-4 items-center p-4 max-lg:pb-4 lg:px-[58px] lg:w-full unstyled-link max-lg:border-b-[2px] lg:border-s-[2px] max-lg:relative ${
        active
          ? "text-black dark:text-white font-medium border-[#EEEEEE] dark:border-tertiary-gray lg:border-black dark:lg:border-white lg:after:hidden after:absolute after:-bottom-[2px] after:start-0 after:w-full after:h-[4px] after:bg-black dark:after:bg-white after:rounded-lg"
          : "text-tertiary-gray hover:text-black dark:hover:text-white max-lg:border-[#EEEEEE] max-lg:dark:border-tertiary-gray lg:border-transparent"
      }`}
    >
      {TabIcon && <TabIcon className="size-[16px] lg:size-[20px]" />}
      {children}
    </Link>
  );
};

export default TabButton;
