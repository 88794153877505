import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import Logo from "../Logo";
import config from "@/config.json";
import Button from "../Button";
import NotFoundPage from "@/pages/404";
import Copy from "../Copy";

const FallbackError = () => {
  const error: Error = useRouteError() as Error;

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFoundPage />;
    }
  }

  return (
    <div className="size-full min-h-screen flex items-center justify-center py-20 px-4 sm:px-10">
      <div className="flex flex-col gap-4 items-center text-center">
        <Logo beta={false} size={64} />
        <h2>Oops, something went wrong!</h2>
        <p>
          Sorry, something happened that prevented Mohtawa from properly
          running.
          <br />
          If this issue persists, please{" "}
          <a
            href={config.support.helpdesk}
            target="_blank"
            rel="noopener noreferrer"
          >
            report it here
          </a>
          .
        </p>
        <div className="bg-white dark:bg-tertiary-950 border border-tertiary-200 dark:border-tertiary-900 rounded-2xl px-6 py-4 w-full max-w-lg text-sm text-center font-mono break-words relative">
          {error?.stack || "No error information found."}
        </div>
        {error?.stack && (
          <Copy text={error?.stack ?? ""}>Copy to clipboard</Copy>
        )}
        <div className="flex max-sm:flex-col max-sm:w-full items-center gap-4">
          <Button
            onClick={() => window.location.reload()}
            variant="blue"
            className="max-sm:w-full"
          >
            Reload Page
          </Button>
          <Button href="/" variant="muted" className="max-sm:w-full">
            Back to Homepage
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FallbackError;
