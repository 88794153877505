import { Link } from "react-router-dom";
import { ReactComponent as LogoSVG } from "../assets/logo.svg";
import { cn } from "@/utils/utils";

interface LogoProps {
  size?: number;
  className?: string;
  beta?: boolean;
  link?: boolean;
}

const Logo = ({
  size = 51,
  className = "",
  beta = true,
  link = true,
}: LogoProps) => {
  const LogoComponent = (
    <LogoSVG
      width={size}
      height={size}
      className={cn("fill-black dark:fill-white", className)}
    />
  );

  return (
    <div className="flex">
      {link ? (
        <Link
          to="/"
          className={cn({
            "flex items-end gap-3 md:gap-4": beta,
          })}
          aria-label="Mohtawa Logo"
        >
          {LogoComponent}
        </Link>
      ) : (
        LogoComponent
      )}
    </div>
  );
};

export default Logo;
