import { RootState } from "redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SignupAuthState {
  email: string;
  first_name: string;
  last_name: string;
  referral_code?: string;
}

const initialState: SignupAuthState = {
  email: "",
  first_name: "",
  last_name: "",
};

export const signupAuthSlice = createSlice({
  name: "signupAuth",
  initialState,
  reducers: {
    updateFields: (
      state,
      action: PayloadAction<
        {
          field: keyof SignupAuthState;
          value: string;
        }[]
      >
    ) => {
      action.payload.forEach(({ field, value }) => {
        state[field] = value;
      });
    },
    resetFields: () => initialState,
  },
});

export const { updateFields, resetFields } = signupAuthSlice.actions;

export const fieldsSelector = (state: RootState) => state.signupAuthSlice;

export default signupAuthSlice.reducer;
