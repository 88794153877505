interface CarouselPillProps {
  children: React.ReactNode;
}

const CarouselPill = ({ children }: CarouselPillProps) => {
  return (
    <div className="rounded-full max-[268px]:px-4 min-[268px]:text-2xl font-medium min-[268px]:w-[13.5rem] py-2 min-[268px]:py-4 border-2 border-white flex items-center justify-center">
      {children}
    </div>
  );
};

export default CarouselPill;
