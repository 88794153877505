import Button from "@/components/Button";
import Popover from "@/components/Popover";
import FilteredPicker from "./FilteredPicker";

import { ReactComponent as GlobeIcon } from "@/assets/icons/misc/globe.svg";

const CurrencyPicker = () => {
  return (
    <>
      <Popover
        anchor="bottom end"
        button={
          <Button
            variant="default-dimmed"
            tiny={true}
            icon={GlobeIcon}
          ></Button>
        }
        className="[--anchor-gap:12px] w-[280px] space-y-6 !px-0 !pe-[6px] !pb-0 !overflow-y-hidden"
      >
        <FilteredPicker wrapperClassName="max-h-[389px]" />
      </Popover>
    </>
  );
};

export default CurrencyPicker;
