import Button from "@/components/Button";

const CheckoutButton = () => {
  return (
    <Button
      href="/cart"
      variant="gradient"
      className="bg-gradient-[110deg] from-[#D370E4] via-[#6B56DA] to-[#4C9FC0] dark:from-accent-main dark:to-accent-main text-white dark:text-black w-full mb-[14px] text-sm !py-4"
    >
      Go to checkout
    </Button>
  );
};

export default CheckoutButton;
