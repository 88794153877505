import { Outlet } from "react-router-dom";
import Header from "@/components/Header";
import Side from "./components/Side";
import clsx from "clsx";

export interface SignupLayoutProps {
  creatorPage?: boolean;
}

const SignupLayout = ({ creatorPage = false }: SignupLayoutProps) => {
  return (
    <>
      <Header
        logoOnly
        searchBar={false}
        userPfPOnly
        className="absolute top-0 left-0"
      />
      <main
        className={`w-full flex items-stretch dark:bg-black ${
          creatorPage
            ? "gap-y-4 max-lg:flex-col lg:flex-row"
            : "gap-2 md:gap-4 max-lg:flex-col lg:flex-row"
        }`}
      >
        <section
          className={clsx(
            "w-full md:min-h-screen pb-10 flex justify-center px-[1.25rem] md:px-[3.75rem]",
            {
              "max-lg:order-2 lg:pt-[102px] lg:items-center": creatorPage,
              "max-lg:order-2 lg:pt-[130px] lg:items-center": !creatorPage,
            }
          )}
        >
          <Outlet />
        </section>
        <Side creatorPage={creatorPage} />
      </main>
    </>
  );
};

export default SignupLayout;
