import { Outlet } from "react-router-dom";
import Footer from "@/components/Footer";
import Header from "@/components/Header";

const MainLayout = () => {
  return (
    <>
      <Header className="mb-8 shadow-lg shadow-gray-50 dark:shadow-none" />
      <Outlet />
      <Footer />
    </>
  );
};

export default MainLayout;
