import { forwardRef } from "react";
import { useAppSelector } from "@/redux/hooks";
import { isLoggedInSelector } from "@/redux/App";
import Logo from "components/Logo";
import Nav from "./Nav";

interface HeaderProps {
  className?: string;
  logoOnly?: boolean;
  searchBar?: boolean;
  invertedColors?: boolean;
  userPfPOnly?: boolean;
  invertLogo?: boolean;
  invertLoginColor?: boolean;
}

const Header = forwardRef(
  (
    {
      className = "",
      logoOnly = false,
      searchBar = true,
      invertedColors = false,
      invertLogo = false,
      userPfPOnly = false,
      invertLoginColor = false,
    }: HeaderProps,
    ref: any
  ) => {
    const isLoggedIn = useAppSelector((state) => isLoggedInSelector(state));

    return (
      <header
        className={`w-full container max-w-full flex justify-between gap-4 xl:gap-10 py-4 items-center h-[5rem] ${className}`}
        ref={ref}
        dir="ltr"
      >
        <Logo
          className={`shrink-0 w-8 md:w-10 lg:w-auto size-[2.75rem] ${
            invertLogo ? (invertedColors ? "fill-white" : "") : ""
          }`}
        />
        {(!logoOnly || isLoggedIn) && (
          <Nav
            searchBar={searchBar}
            invertedColors={invertedColors}
            userPfPOnly={userPfPOnly}
            invertLogo={invertLogo}
            invertLoginColor={invertLoginColor}
          />
        )}
      </header>
    );
  }
);

export default Header;
