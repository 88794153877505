import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PaymentMode, PaymentPlan } from "@/shared/types/subscription";

interface SubscriptionState {
  step: number;
  plan: PaymentPlan;
  payment: {
    mode: PaymentMode;
  };
  form: {
    company_type: string | null;
    company_name: string;
    company_site: string;
    company_desc: string;
  };
}

const initialState: SubscriptionState = {
  step: 1,
  plan: "free",
  payment: {
    mode: "yearly",
  },
  form: {
    company_type: null,
    company_name: "",
    company_site: "",
    company_desc: "",
  },
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    changePaymentMode: (state, action: PayloadAction<PaymentMode>) => {
      state.payment.mode = action.payload;
    },
    selectPaymentPlan: (state, action: PayloadAction<PaymentPlan>) => {
      state.plan = action.payload;
    },
    selectCompanyType: (state, action: PayloadAction<string>) => {
      state.form.company_type = action.payload;
    },
    saveForm: (
      state,
      action: PayloadAction<{
        name: string;
        url: string;
        desc: string;
      }>
    ) => {
      const { name, url, desc } = action.payload;
      state.form.company_name = name;
      state.form.company_site = url;
      state.form.company_desc = desc;
    },
  },
});

export const {
  changePaymentMode,
  setStep,
  selectPaymentPlan,
  selectCompanyType,
  saveForm,
} = subscriptionSlice.actions;

export const stepSelector = (state: RootState) => state.subscriptionSlice.step;
export const paymentPlanSelector = (state: RootState) =>
  state.subscriptionSlice.plan;
export const paymentModeSelector = (state: RootState) =>
  state.subscriptionSlice.payment.mode;
export const companyTypeSelector = (state: RootState) =>
  state.subscriptionSlice.form.company_type;
export const formDataSelector = (state: RootState) =>
  state.subscriptionSlice.form;

export default subscriptionSlice.reducer;
