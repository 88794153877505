import axios from "axios";
import { refreshToken } from "@/services/authService";
import { store } from "@/redux/store";

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const user = store.getState().appSlice.user;

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      user.token
    ) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken(user.token, user.refreshToken);
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);
