import { isLoggedInSelector, userRolesSelector } from "@/redux/App";
import { useAppSelector } from "@/redux/hooks";
import { Navigate, useLocation } from "react-router-dom";

interface AuthRouteProps {
  element: JSX.Element;
  creatorPage?: boolean;
  condition?: boolean;
}

const AuthRoute: React.FC<AuthRouteProps> = ({
  element,
  creatorPage = false,
  condition = true,
}) => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isCreator = useAppSelector(userRolesSelector)?.includes("Creator");
  const location = useLocation();

  if (creatorPage && !isCreator) {
    return <Navigate to="/creator-dashboard/purchases" replace />;
  }

  return isLoggedIn && condition ? (
    element
  ) : (
    <Navigate to={`/sign-in/?target=${location.pathname}`} replace />
  );
};

export default AuthRoute;
