import { useLocation } from "react-router-dom";
import { useScrollable } from "@/hooks/useScrollable";
import TabButton from "./TabButton";
import Divider from "../Divider";

// Icons
import { ReactComponent as WandIcon } from "@/assets/icons/creator_dashboard/wand.svg";
import { ReactComponent as CreatedIcon } from "@/assets/icons/creator_dashboard/created.svg";
import { ReactComponent as HelpIcon } from "@/assets/icons/creator_dashboard/help.svg";
import { ReactComponent as SettingsIcon } from "@/assets/icons/creator_dashboard/cog.svg";
import { useAppSelector } from "@/redux/hooks";
import { userRolesSelector } from "@/redux/App";

const SideMenu = () => {
  const { pathname } = useLocation();
  const [tabsRef, { onMouseDown }] = useScrollable();
  const isCreator = useAppSelector(userRolesSelector)?.includes("Creator");

  return (
    <div className="lg:bg-tertiary-50 lg:dark:bg-tertiary-dark-gray lg:rounded-2xl overflow-clip text-center lg:pt-8 pb-8 lg:pb-10">
      <div
        className="max-lg:w-full flex lg:flex-col lg:space-y-3 overflow-x-auto whitespace-nowrap scrollbar-hide justify-center"
        ref={tabsRef}
        onMouseDown={onMouseDown}
      >
        {isCreator && (
          <TabButton
            icon={CreatedIcon}
            active={pathname === "/creator-dashboard"}
            href="/creator-dashboard"
          >
            Created
          </TabButton>
        )}
        <TabButton
          icon={WandIcon}
          active={pathname === "/creator-dashboard/purchases"}
          href="/creator-dashboard/purchases"
        >
          Purchased
        </TabButton>
      </div>
      <Divider className="max-lg:hidden my-[16px]" />
      <ul className="space-y-3 max-lg:hidden">
        <TabButton icon={HelpIcon} href="/faq">
          Help
        </TabButton>
        <TabButton icon={SettingsIcon} href="/settings">
          Settings
        </TabButton>
      </ul>
    </div>
  );
};

export default SideMenu;
