import { useEffect, useRef, useState, useCallback } from "react";

interface ScrollableHandlers {
  onMouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  scrollLeft: () => void;
  scrollRight: () => void;
  isAtStart: boolean;
  isAtEnd: boolean;
}

export const useScrollable = (): [
  React.RefObject<HTMLDivElement>,
  ScrollableHandlers
] => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeftPos, setScrollLeftPos] = useState<number>(0);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const checkScrollPosition = useCallback(() => {
    if (scrollableRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollableRef.current;
      const isRTL = getComputedStyle(document.body).direction === "rtl";

      const scrollCeil = Math.ceil(Math.abs(scrollLeft) + clientWidth);

      if (isRTL) {
        setIsAtStart(scrollCeil >= scrollWidth);
        setIsAtEnd(scrollLeft === 0);
      } else {
        setIsAtStart(scrollLeft === 0);
        setIsAtEnd(scrollCeil >= scrollWidth);
      }
    }
  }, []);

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!scrollableRef.current) return;
    setIsDragging(true);
    setStartX(event.pageX - scrollableRef.current.offsetLeft);
    setScrollLeftPos(scrollableRef.current.scrollLeft);
    scrollableRef.current.classList.add("select-none");
  };

  const onMouseUp = () => {
    setIsDragging(false);
    if (scrollableRef.current) {
      scrollableRef.current.classList.remove("select-none");
    }
  };

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!isDragging || !scrollableRef.current) return;
      const x = event.pageX - scrollableRef.current.offsetLeft;
      const walk = (x - startX) * 1.45; // Speed
      scrollableRef.current.scrollLeft = scrollLeftPos - walk;
      checkScrollPosition();
    },
    [isDragging, scrollLeftPos, startX, checkScrollPosition]
  );

  const scrollToItem = (direction: "left" | "right") => {
    if (scrollableRef.current) {
      const firstChild = scrollableRef.current.firstElementChild;
      if (!firstChild) return;

      const itemWidth = firstChild.clientWidth;
      const gap = parseFloat(getComputedStyle(scrollableRef.current).gap) || 0;
      const itemTotalWidth = itemWidth + gap;
      const scrollLeftPos = scrollableRef.current.scrollLeft;
      const isRTL =
        getComputedStyle(document.documentElement).direction === "rtl";
      const currentIndex = Math.round(scrollLeftPos / itemTotalWidth);
      const newIndex =
        direction === (isRTL ? "right" : "left")
          ? currentIndex - 1
          : currentIndex + 1;
      const newScrollLeft = newIndex * itemTotalWidth;

      scrollableRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const scrollLeft = () => scrollToItem("left");
  const scrollRight = () => scrollToItem("right");

  useEffect(() => {
    if (scrollableRef.current) {
      checkScrollPosition();
      scrollableRef.current.addEventListener("scroll", checkScrollPosition);
    }

    const ref = scrollableRef.current;

    return () => {
      if (ref) {
        ref.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, [checkScrollPosition]);

  useEffect(() => {
    if (isDragging) {
      document.body.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("mouseup", onMouseUp);
    } else {
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("mouseup", onMouseUp);
    };
  }, [isDragging, onMouseMove]);

  return [
    scrollableRef,
    {
      onMouseDown,
      scrollLeft,
      scrollRight,
      isAtStart,
      isAtEnd,
    },
  ];
};
