import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import Menu from "./Menu";

import { IoClose } from "react-icons/io5";

interface MobileMenuProps {
  invertedColors?: boolean;
}

const MobileMenu = ({ invertedColors = false }: MobileMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <button
        className="md:hidden flex flex-col gap-2 w-[20px] shrink-0"
        aria-label="Open Mobile Menu"
        onClick={() => setIsMenuOpen(true)}
      >
        <span
          className={`w-full h-[2px] ${
            invertedColors ? "bg-white" : "bg-black dark:bg-white"
          }`}
        ></span>
        <span
          className={`w-full h-[2px] ${
            invertedColors ? "bg-white" : "bg-black dark:bg-white"
          }`}
        ></span>
      </button>
      <Dialog
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        className="relative z-50"
      >
        {/* <div className="fixed inset-0 bg-black/50" aria-hidden="true" /> */}
        <div className="fixed top-0 right-0 flex w-screen items-start justify-end">
          <button onClick={() => setIsMenuOpen(false)}>
            <IoClose size={28} className="text-tertiary-gray mx-1 mt-6" />
          </button>
          <DialogPanel className="w-full space-y-4 bg-white dark:bg-tertiary-950 pt-12 pb-6 h-screen shadow-lg max-w-[326px] flex flex-col justify-between overflow-y-auto">
            <Menu />
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default MobileMenu;
