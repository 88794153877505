import { useEffect } from "react";
import { useAppSelector } from "@/redux/hooks";
import { uploadStateSelector } from "@/redux/Upload";
import { AnimatePresence, motion } from "framer-motion";
import Uploader from "@/components/Uploader";

const UploadQueue = () => {
  const uploadState = useAppSelector(uploadStateSelector);

  useEffect(() => {
    const handleWarnUser = (e) => {
      if (e) {
        e.returnValue = "File upload is in progress. Are you sure?";
      }
      return "File upload is in progress. Are you sure?";
    };

    if (uploadState.upload.isUploading) {
      window.addEventListener("beforeunload", handleWarnUser);
    }

    return () => {
      window.removeEventListener("beforeunload", handleWarnUser);
    };
  }, [uploadState.upload.isUploading]);

  return (
    <AnimatePresence>
      {uploadState.upload.isUploading && (
        <motion.div
          initial={{ x: "50%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "50%", opacity: 0 }}
          className="fixed bottom-6 end-0 sm:end-6 z-30 max-sm:w-full"
        >
          <Uploader
            filename={uploadState.upload.filename ?? "File"}
            progress={uploadState.upload.progress}
            size={uploadState.upload.filesize ?? 0}
            className="w-full sm:min-w-[438px] bg-white/70 dark:bg-tertiary-dark-gray/70 backdrop-blur"
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default UploadQueue;
