import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// Translation files
import commonEN from "./locales/en/common.json";
import commonAR from "./locales/ar/common.json";
// Pages
import landingPageEN from "./locales/en/pages/landing.json";
import landingPageAR from "./locales/ar/pages/landing.json";
// Features
import searchFeatureEN from "./locales/en/features/search.json";
import searchFeatureAR from "./locales/ar/features/search.json";

const resources = {
  en: {
    common: commonEN,
    pages: {
      landing: landingPageEN,
    },
    features: {
      search: searchFeatureEN,
    },
  },
  ar: {
    common: commonAR,
    pages: {
      landing: landingPageAR,
    },
    features: {
      search: searchFeatureAR,
    },
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: localStorage?.getItem("mo7tawa-locale") ?? "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    ns: ["common", "pages", "features"],
    defaultNS: "common",
    resources,
    // TODO: Load translations from Backend
    // backend: {
    //   loadPath: "/locales/{{lng}}/{{ns}}.json",
    // },
  });

export default i18n;
