import { twMerge } from "tailwind-merge";
import ReactSlider from "react-slider";

interface SliderProps {
  min?: number;
  max?: number;
  defaultValue?: number;
  classes?: {
    containerClass?: string;
    sliderClass?: string;
  };
  onChange: (val: number) => void;
}

const Slider = ({
  min = 0,
  max = 200,
  defaultValue = 0,
  classes,
  onChange,
}: SliderProps) => {
  return (
    <div className="flex items-center gap-1.5 font-medium">
      <span>-</span>
      <ReactSlider
        className={twMerge(
          "relative w-[329px] h-[25px] flex items-center",
          classes?.containerClass
        )}
        trackClassName="h-1 bg-black dark:bg-white rounded"
        thumbClassName="size-[19px] rounded-full bg-accent-blue dark:bg-accent-main"
        min={min}
        max={max}
        defaultValue={defaultValue}
        onChange={onChange}
      />
      <span>+</span>
    </div>
  );
};

export default Slider;
