import { useState } from "react";
import config from "@/config.json";

import bug_light from "@/assets/icons/faq/bug_light.svg";
import bug_open_light from "@/assets/icons/faq/bug_open_light.svg";
import bug_dark from "@/assets/icons/faq/bug_dark.svg";
import bug_open_dark from "@/assets/icons/faq/bug_open_dark.svg";
import { AnimatePresence, motion } from "framer-motion";

const StickyBug = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="fixed bottom-20 end-4 md:bottom-24 md:end-8 z-20">
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0, scaleX: 0 }}
            animate={{ opacity: 1, scaleX: 1 }}
            exit={{ opacity: 0, scaleX: 0 }}
            transition={{ ease: "backIn", duration: 0.265 }}
            className="w-[14.875rem] h-[3.75rem] bg-accent-blue dark:bg-accent-main rounded-full text-sm text-white dark:text-black size-full flex items-center ps-[14px] origin-right rtl:origin-left absolute top-0 end-0"
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            <p className="whitespace-nowrap">
              Found a bug?{" "}
              <a
                className="unstyled-link underline"
                href={config.support.helpdesk}
                target="_blank"
                rel="noreferrer"
              >
                Report it
              </a>
            </p>
          </motion.div>
        )}
      </AnimatePresence>

      <button
        className="absolute top-0 end-0 size-[60px] rounded-full shadow-[0px_4px_20px_0px_rgba(128,128,128,0.18)]"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {open ? (
          <>
            <img
              src={bug_open_light}
              alt="Report a Bug"
              className="dark:hidden size-full"
            />
            <img
              src={bug_open_dark}
              alt="Report a Bug"
              className="hidden dark:block size-full"
            />
          </>
        ) : (
          <>
            <img
              src={bug_light}
              alt="Report a Bug"
              className="dark:hidden size-full"
            />
            <img
              src={bug_dark}
              alt="Report a Bug"
              className="hidden dark:block size-full"
            />
          </>
        )}
      </button>
    </div>
  );
};

export default StickyBug;
